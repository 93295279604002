@import "~bootstrap";
@import "~react-toastify/dist/ReactToastify.css";
@import "~pulse-modal/dist/styles/scss/index";
@import "~pulse_table/dist/styles/scss/index";
@import "~store-fetch-wrappers/dist/styles/scss/index";

@import "core/animations";
@import "core/buttons";
@import "core/cards";
@import "core/colours";
@import "core/dropdown";
@import "core/form";
@import "core/icons";
@import "core/inputs";
@import "core/miniCalendar";
@import "core/modal";
@import "core/navigation";
@import "core/notificationsPanel";
@import "core/number-bubble";
@import "core/page";
@import "core/react-date-picker";
@import "core/sidebar";
@import "core/statistics";
@import "core/table";
@import "core/toast";
@import "core/tooltip";
@import "core/totp";
@import "core/typography";
@import "core/utility";
@import "core/variables";

@import "widgets/tabs";
@import "widgets/topbar";

@import "pages/dashboard";

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  overflow-x: hidden !important;
  background-color: $c1;
}

#root {
  height: 100%;
}