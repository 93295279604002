@import "colours";
@import "typography";

.mc-table {
  width: 100%;
  border-collapse: collapse;
}

.mc-table-header {
  //background: $mc-blue-01;
  background: #ebeef2;
  //border-bottom: 1px solid $mc-blue-01;
}

.mc-table-header-item {
  width: 20%;
  min-width: 150px !important;
  font-family: $header-font;
  //color: $mc-white-01;
  color: #7b8185;
  font-size: 1rem;
  font-weight: 400;
}

.mc-table-row {
  border-bottom: 1px solid #eee;

  &:nth-child(odd) {
    background-color: $mc-white-01;
  }
}

.mc-table-row-item {
  width: 20%;
  font-family: $body-font;
  font-size: 0.9em;
  font-weight: 400;
}

.table-overflow-x {
  overflow-x: auto;
}

.table-overflow-y {
  overflow-y: visible !important;
}
