@import "colours";
@import "variables";

.number-bubble-yellow,
.number-bubble-dark-blue{
  position: absolute;
  right: -11px;
  top: -16px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid $mc-white-01;
  transition: background-color ease $animation-duration;
}

.number-bubble-value {
  color: $mc-white-01;
  font-size: 0.8em;
  text-align: center;
  padding-top: 1px;
  padding-right: 1px;
}

.number-bubble-yellow {
  background-color: $mc-yellow-01;
}

button:hover ~ div.number-bubble-yellow {
  cursor: pointer;
  background-color: darken($mc-yellow-01, 10%);
}

.number-bubble-dark-blue {
  background-color: $mc-blue-01;
}

button:hover ~ div.number-bubble-dark-blue {
  cursor: pointer;
  background-color: $mc-dark-blue-hover;
}
