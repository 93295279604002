@import "colours";
@import "variables";
@import "typography";

.live-tracker-header-item {
  display: inline-block;

  h6 {
    font-family: Inter, sans-serif;

    span {
      font-weight: bold;
    }
  }
}

.action-tracker-panel {
  color: $mc-blue-01;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.notification-item-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notification-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.notification-item-spacer {
  height: 1px;
  background-color: $c4;
}

b.notification-bold-text {
  font-weight: normal;
}

.notification-item-content {
  flex-grow: 1;

  h3 {
    margin: 0 0 0.5rem 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
  }

  a {
    color: $c1-content;
    text-decoration: underline !important;
    font-weight: 500;
    font-size: 0.9rem
  }

  .notification-item-link {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    text-align: right;
  }
}

.notification-item-icon-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem;
    margin-top: 0;
    border-radius: 5px;
    background-color: #fedf89;
}

.notification-item-icon {
  width: 20px;
  height: 20px;
  color: #e88043;
}

.red-icon {
  color: #FB1818;
}
.orange-icon {
  color: #FB8518;
}
.yellow-icon {
  color: #FED545;
}
.dark-blue-icon {
  color: $mc-blue-01;
}
