@import "~react-calendar/dist/Calendar.css";
@import "colours";
@import "typography";
@import "variables";

.calendar-wrapper {
  background: transparent;
  border-radius: 2px;
  width: 100%;
  max-height: 450px;
  align-items: center;
  justify-content: center;
}

.calendar {
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}

.calendar-key {
  display: flex;
  align-self: flex-start;
  padding-left: 10px;
  flex-grow: 1;
  color: $mc-blue-01;
  font-size: 12px;
  flex-direction: column;
  position: absolute;
  bottom: 25px;
}

.calendar-key-item {
  display: flex;
  align-self: flex-start;
  font-family: $body-font;
}

.open-event,
.booked-event {
  width: 10px;
  height: 10px;
}

.open-event {
  border: 1px solid $mc-blue-01;

}

.booked-event {
  border: 1px solid $mc-blue-01;
  background-color: $mc-yellow-01 !important;
}

.react-calendar {
  width: 100%;
  background: transparent !important;
  border: none !important;
  font-family: $body-font !important;
  color: $mc-blue-01 !important;
  line-height: 1.125em;
}
.react-calendar__navigation__label__labelText {
  font-family: $header-font !important;
}

.react-calendar__month-view__weekdays,
.react-calendar__navigation {
  border-bottom: 1px solid $mc-blue-01 !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: darken($mc-grey-02, 10%) !important;
  transition: all ease $animation-duration;
  -webkit-transition: all ease $animation-duration;
  -moz-transition: all ease $animation-duration;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 0 !important;
}

.react-calendar__navigation button[disabled] {
  background: lighten($mc-yellow-01, 10%) !important;
}

.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label,
.react-calendar__navigation__label__labelText--from,
.react-calendar__navigation__arrow,
.react-calendar__navigation__next-button,
.react-calendar__tile,
.react-calendar__month-view__days__day {
  color: $mc-blue-01 !important;
  //border-bottom: 1px solid $light-theme-font !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: lighten($mc-blue-01, 10%) !important;
}

.react-calendar__tile--now {
  background: $mc-grey-02 !important;
}

.react-calendar__tile--active {
  background-color: $mc-grey-02 !important;
  color: $mc-blue-01 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: darken($mc-grey-02, 10%) !important;
  transition: all ease $animation-duration;
  -webkit-transition: all ease $animation-duration;
  -moz-transition: all ease $animation-duration;
}

.calendar-booked-date {
  background-color: $mc-yellow-01 !important;
  color: $mc-blue-01 !important;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}
