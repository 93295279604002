@import "colours";
.totp-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.totp-error-card-text-container {
  width: 275px;
}

.totp-item.left {
  width: 60%;
  margin: 0 2rem 2rem 0;
}

.totp-item.right {
  width: 30%;
  margin-left: 2rem;
}

.totp-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.totp-method-container {
  display: flex;
  width: 100%;

  justify-content: center;
}

.totp-method-wrapper {
  background-color: $mc-grey-05;
  border-radius: 20px;

  padding: 5px;
}

.totp-qr-code-card-container {
  display: flex;
  justify-content: flex-end;
}

.totp-qr-code-card {
  align-items: center;
}

.totp-qr-code-cta-text {
  text-align: center;
  margin: 2rem 0 0;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 3px;
}

.totp-secret-code-card-container {
  display: flex;
  justify-content: flex-end;
}

.totp-secret-code-card-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.totp-success-container {
  width: 275px;
  display: flex;
  justify-content: center;
}

.totp-error,
.totp-success {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 275px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 175px;
    position: relative;
    z-index: 10;
  }
}

.totp-success {
  background-color: rgba(#D0D700, .2);
  color: $mc-blue-01;
}

.totp-error {
  background-color: rgba($mc-red-01, .2);
}

.totp-secret-code-card {
  justify-content: space-between;
  min-height: 600px;
  height: 100px;
  border-radius: 30px;

  width: 90%;

  .mock-input {
    background: $mc-white-01;
    color: $mc-grey-01;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 0.825rem;
  }

  .key-type-row {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
  }

  .mock-select {
    width: 45%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 0.825rem;
    border-radius: 5px;
    border: 1px solid $mc-grey-01;
    color: $mc-grey-01;
    justify-content: space-between;
  }

  .mock-button {
    width: 35%;
    height: 50px;
    background: $mc-blue-01;
    display: flex;
    align-items: center;
    font-size: 0.825rem;
    color: white;
    justify-content: center;
    border-radius: 5px;
  }

  .mock-navbar-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .mock-navbar {
    height: 4px;
    width: 200px;
    background: $mc-blue-01;
    border-radius: 2px;
  }
}

.secret-key {
  text-decoration: underline;
  cursor: pointer;
  word-wrap: break-word;
}

.totp-image-container {
  background-color: rgba(#D0D700, .2);
  overflow: hidden;
  border-radius: 10px;
  position: relative;

  .qr-code-image-wrapper {
    position: relative;
    display: flex;
    z-index: 10;
    width: 100%;
    max-width: 341px;
    max-height: 341px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.dot {
  background-color: rgba(#D0D700, .2);
  border-radius: 50%;
  position: absolute;
}

.dot.error {
  background-color: rgba($mc-red-01, .2);
}

.dot.bottom-left {
  height: 100px;
  width: 100px;
  left: -30px;
  bottom: -30px;
}

.dot.top-right {
  height: 215px;
  width: 215px;
  top: -67px;
  right: -67px;
}

.totp-error-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin: 0 0 2rem;

  img {
    width: 30%;
    filter: invert(1);
  }
}


@media only screen and (max-width: 1250px) {
  .totp-item.left {
    width: 55%;
  }

  .totp-item.right {
    width: 35%;
  }
}

@media only screen and (max-width: 991px) {
  .totp-item.left {
    width: 50%;
  }

  .totp-item.right {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .totp-container {
    flex-direction: column-reverse;
  }

  .totp-item.left {
    width: 100%;
    margin: 0 0 2rem;
  }

  .totp-item.right {
    width: 100%;

    margin: 2rem 0;
  }

  .totp-secret-code-card {
    width: 350px;
  }

  .totp-qr-code-card-container,
  .totp-secret-code-card-container {
    justify-content: center;
  }
}
