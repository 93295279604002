@import "colours";
@import "typography";

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, max-content));
  grid-gap: 90px;
  padding: initial;
  justify-content: center;
  margin: 15px 20px;
}

.stats-item {
  display: grid;
  grid-template-columns: 0fr 0fr;
  grid-gap: 2px;
  justify-content: center;
}

.outer-label-text {
  color: $mc-blue-02;
  font-family: $header-font;
  text-align: center;
}

.half-donut {
  max-width: 250px;
}

.donut-needle-parent {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 120px;
  left: 135px;
  transition: all ease 1s;
  transition-delay: .5s;
}

.donut-needle {
  width: 75px;
  position: absolute;
  transform: translate(50%, 50%);
  top: -22px;
  left: -94px;
}

.bar-wrapper {
  width: 37px;
}

.bar {
  height: 126px;
}

.bar-needle-parent {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 112px;
  left: 46px;
  transition: all ease 1s;
  transition-delay: .5s;
}

.bar-needle {
  width: 20px;
  height: 20px;
  position: absolute;
  transform: translate(50%, 50%);
  top: -15px;
  left: -20px;
}

.mobile-stats-container {
  display: inherit !important;
}

@media only screen and (max-width: 425px) {
  .mobile-stats-container {
    display: none !important;
  }
}


