@import "../core/colours";

div.dash-container {
  flex-grow: 1;
  background-color: $c8;
  display: flex;
  flex-direction: column;

  .top-bar {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

div.dash-content {
  flex-grow: 1;
  border-top: 1px solid $c4;
  display: grid;
  //grid-template-columns: 900px 1fr;
  grid-template-columns: 2fr 1fr;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr;
  }
}

div.dash-widgets-panel {
  padding: 2rem;

  @media (max-width: 800px) {
    padding: 1rem;
  }
}

div.dash-notifications {
  background-color: $c1;
  padding: 1.5rem;

  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

// Main layout for the widgets
div.dash-widgets {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "drug_stats drug_stats"
    "absences compliance";
  gap: 2rem;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    "drug_stats"
    "absences"
    "compliance";
    gap: 1rem;
  }
}

// Individual widget in the layout
div.dash-widget {
  background-color: $c1;
  padding: 1.5rem;
  border-radius: 10px;

  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }

  // Specific locations for each widget
  &.drug_stats {
    grid-area: drug_stats;
  }

  &.absences {
    grid-area: absences;
  }

  &.compliance {
    grid-area: compliance;
  }
}

div.dash-widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }

  a {
    color: $c1-content;
    text-decoration: underline !important;
    font-weight: 500;
    font-size: 0.9rem;
  }
}

// Container for all drug widgets, lining them up in a row
div.drug-widgets {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    gap: 0.9rem;
  }
}

// Each individual statistic widget for drugs
div.drug-widget {
  background-color: #edf9f0;
  color: $c1-content;
  border-radius: 10px;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.1rem;

  div.drug-widget-icon {
    color: #2a8a41;
  }

  div.drug-widget-icon-wrapper {
    display: flex;
    gap: 0.7rem;
    width: fit-content;
    //flex-direction: column;
    //align-items: center;
  }

  // Rounded background for icons that don't have a round background
  div.drug-widget-icon-rounded {
    background-color: red;
    color: white;
    border-radius: 999px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p.drug-widget-count {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-top: 0.2rem;
  }

  a.drug-widget-description {
    color: $c1-content;
    font-weight: 500;
    font-size: 0.9rem;
    text-decoration: underline !important;
  }

  // Overrides for non-green widgets

  &.drug-widget-expired {
    background-color: #feefef;

    div.drug-widget-icon {
      color: #c70a0a;
    }
  }

  &.drug-widget-30 {
    background-color: #fafafa;

    div.drug-widget-icon-rounded {
      background-color: #858c91;
    }
  }

  &.drug-widget-7 {
    background-color: #fffbf2;

    div.drug-widget-icon-rounded {
      background-color: #e0a309;
    }
  }
}

div.dash-centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.dash-stat-list {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0 0;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;

    p {
      margin: 0;
      padding: 0;
    }

    .stat-label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.9rem;
    }

    .stat-button {
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 999px;
    }

    .stat-value {
      font-weight: 500;
    }

    .stat-breakdown {
      color: $c3;
      font-size: 0.8rem;
    }
  }
}