@import "animations";
@import "colours";
@import "typography";
@import "variables";

.layout-wrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  animation-name: fadeInOpacity;
  animation-duration: $animation-duration;
}

.nav_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding-left: 6.5rem;
  }
}

.opacity-override {
  opacity: 1 !important;
}

.header-wrapper {
  box-shadow: none;
  height: 70px;
  background-color: $mc-blue-01;
}

.header .flex-row {
  height: 100%;
}

.stuck {
  top: 0;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
}

.page-container,
.nav-container {
  max-width: 1900px;
  padding: 0 2rem;
  margin: 0 auto;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.mc-logo {
  width: 209px !important;
  margin-right: 30px;
  line-height: 1;
  padding: 20px 0;
}

.nav-text {
  color: $mc-white-01;
  font-family: $header-font;
}

.group-sub-system-box,
.nav-links {
  color: $mc-white-01;
  font-family: $header-font;

  &:hover {
    cursor: pointer;
    color: $mc-white-hover !important;
    transition: color ease-in-out $animation-duration;
    text-decoration: none !important;
  }
}

.footer-text {
  color: $mc-white-01;
  font-family: $body-font;
  font-size: 0.8em;
}

.current-system {
  color: $mc-yellow-01 !important;

  &:hover {
    color: $mc-yellow-hover !important;
    transition: color ease-in-out $animation-duration;
  }
}

.sub-system-bar {
  min-height: 35px;
  background-color: $mc-grey-06;
  opacity: 1;
}
.sub-system-links {
  margin: 5px 0;
}

.footer-line {
  border-bottom: 1px solid $mc-grey-03;
}

.footer {
  background-color: $mc-blue-01;
}

.link-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.nav-links-right {
  justify-content: flex-end;
}

.no-list-style {
  list-style: none;
}

.link-container>li, {
  display: inline-block;
  list-style: none;
  padding: 0;
  position: relative;
  margin: 0 7px;
  -webkit-transition: background-color $animation-duration;
  -o-transition: background-color $animation-duration;
  transition: background-color $animation-duration;

  &:hover {
    cursor: pointer;
  }
}

.nav-links {
  font-weight: 500;
}

.top-nav-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  max-height: 625px;
  overflow-y: auto;

  animation-name: fadeInOpacity;
  animation-duration: $animation-duration;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.subsystem-nav-dropdown-content {
  display: none;
  position: absolute;
  right: 4px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  max-height: 625px;
  overflow-y: auto;
  padding: 12px 11px 6px 11px;

  animation-name: fadeInOpacity;
  animation-duration: $animation-duration;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.bounceIn {
  animation-name: bounceAnimateIn;
  animation-duration: $animation-duration;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  @for $x from 1 through 50 {
    &:nth-child(#{$x}) {
      animation-delay: 100ms * ($x - 1);
    }
  }
}

.bounceIn-fast {
  animation-name: bounceAnimateIn;
  animation-duration: $animation-duration * 0.5;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  @for $x from 1 through 50 {
    &:nth-child(#{$x}) {
      animation-delay: 100ms * ($x - 1);
    }
  }
}


li:hover .top-nav-dropdown-content {
  display: block;
  background-color: $mc-dark-blue-transparent;
  border-radius: 5px;
}

li:hover .subsystem-nav-dropdown-content {
  display: block;
  background-color: $mc-grey-06;
}

.sub-system-child-route-item {
  padding: 12px 16px;
  margin-bottom: 5px;
  width: 220px;
  background-color: $mc-grey-07;

  &:hover {
    color: $mc-white-01 !important;
    background-color: darken($mc-grey-07, 10%);
    transition: background-color ease-in-out $animation-duration;
  }
}

.sub-system-child-route-item-active-link {
  color: $mc-blue-01;
  background: $mc-yellow-01;

  &:hover {
    color: $mc-blue-01 !important;
    background-color: $mc-yellow-hover;
    transition: background-color ease-in-out $animation-duration;
  }
}

.group-sub-system-box {
  width: 99%;
  position: relative;
  margin: 5px;
  height: 190px;
  display: grid;
  justify-content: center;
  align-content: center;
  background-color: $mc-blue-03;

  &:hover {
    background-color: $mc-dark-blue-disabled;
    transition: background-color ease-in-out $animation-duration;
  }
}

.active-sub-system {
  background-color: $mc-yellow-01 !important;

  &:hover {
    background-color: $mc-yellow-hover !important;
    transition: background-color ease-in-out $animation-duration;
  }
}

.box-image {
  position: relative;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
}

.desktop {
  display: inherit;
}

.mobile {
  display: none;
}

.burger-menu {
  display: grid;
  align-items: center;
  justify-content: center;
}

.logo-container {
  display: inherit;
}

@media only screen and (max-width: 850px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: inherit !important;
  }
}

@media only screen and (max-width: 576px) {
  .logo-container {
    display: none;
  }
}

