@import "colours";
@import "typography";

.modal {
  .modal-text-colour {
    color: $mc-blue-01;
  }
  .header-text {
    font-family: $header-font;
  }
}

.modal-background {
  padding: 0.65rem;
}

.modal-background-window {
  background: $mc-grey-02;
}

.pulse-modal-content-sm {
  overflow-y: visible !important;
}

.modal .content {
  padding: 10px 5px;
  height: calc(100% - 40px);
  overflow-x: visible !important;
  overflow-y: visible !important;
}
.modal .styled-modal-xs {
  max-height: 600px !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
}
