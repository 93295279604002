@import "../core/colours";

.top_tabs {
  display: flex;
  gap: 0.5rem;
  align-items: stretch;
  background-color: $c1;
  color: $c3;
  border-bottom: 1px solid $c4;
  padding: 0.75rem 1rem;
  font-weight: 500;

  @media (max-width: 1024px) {
    display: none;
  }
}

.top_tab {
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  padding: 0.4rem 0.85rem;
  cursor: pointer;
  border-radius: 999px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $c3;

  &.active {
    background-color: #2a5a7b;
    color: white;
  }

  &:hover {
    background-color: #416e90;
    color: white;
  }
}
